import { Column, Row } from "@react-tiny-grid/core"
import { Link } from "gatsby"
import * as React from "react"
import styled from "styled-components"

import Layout from "../components/layout"

const Text = styled.p`
  font-size: 1.2rem;
  font-weight: 300;
  width: 100%;
`

const HFlex = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  @media screen and (max-width: 786px) {
    flex-direction: column;
  }
`

const Button = styled(Link)`
  background-color: #3bc6bd;
  border-radius: 12px;
  padding: 10px 35px;
  text-decoration: none;
  text-align: center;
  font-weight: bold;
  color: #000;
  float: right;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  margin: 10px;
  min-width: 300px;
`

const ShadowBox = styled.div`
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 25px;
  background-color: ${({ color }) => color};
  margin-bottom: 20px;
`

const PartnersPage = () => (
  <Layout>
    <h2>PARTNER PROGRAMS</h2>
    <br />
    <HFlex>
      <Text>Be part of our history and help us achieve key milestones.</Text>
      <Button to="#">Become a Partner !</Button>
    </HFlex>
    <br />
    <br />

    <ShadowBox color="#E5E5E5">
      <h3>Why Partner with us ?</h3>
      <br />
      <Text>
        We offer competitive agreements to help you create added value to your
        products and added revenue stream to your business.
      </Text>
    </ShadowBox>

    <Row>
      <Column>
        <ShadowBox color="#153250">
          <h3 className="text-white">Reseller</h3>
          <br />
          <Text className="text-white text-justify">
            Our valued resellers are enjoying strong returning revenue stream
            with healthy margins and our full support including marketing and
            training,
          </Text>
        </ShadowBox>
      </Column>

      <Column>
        <ShadowBox color="#153250">
          <h3 className="text-white">Reseller</h3>
          <br />
          <Text className="text-white text-justify">
            Our valued resellers are enjoying strong returning revenue stream
            with healthy margins and our full support including marketing and
            training,
          </Text>
        </ShadowBox>
      </Column>
    </Row>

    <div>
      <h3>Become a Partner TODAY !</h3>
      <Text className="text-justify">
        By entering into a partnership with us, you get access to the
        information that will help you build reliable &amp; scalable
        integrations, launch and scale new amazing products, help new customers
        use our awasome digital asset certification solution, get regular
        partner updates, access best practice advices and training access.{" "}
        <span className="text-bold">
          Let’s join forces today for a better tomorrow.
        </span>
      </Text>
    </div>
  </Layout>
)

export default PartnersPage
